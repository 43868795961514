<template>
  <div class="merch">
    <b-img class="merch-item" fluid src="../img/merch/sweatshirt_01.jpg"/>
    <b-img class="merch-item" fluid src="../img/merch/sweatshirt_02.jpg"/>
    <b-img class="merch-item" fluid src="../img/merch/sweatshirt_03.jpg"/>
    <b-img class="merch-item" fluid src="../img/merch/mousepad.png"/>
  </div>
</template>

<script>
export default {
  name: 'Merch'
}
</script>
