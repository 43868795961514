<template>
  <b-row>
    <b-col cols="12" lg="8">
      <merch />
    </b-col>
    <b-col cols="12" lg="4">
      <timeline
        id="teamrme"
        source-type="profile"
        :options="timelineOptions"
      />
  </b-col>
</b-row>
</template>

<script>
import { Timeline } from 'vue-tweet-embed'
import Merch from './Merch.vue';

export default {
  name: 'Home',
  components: { Timeline, Merch },
  data () {
    return {
      timelineOptions: { tweetLimit: '3' }
    }
  }
}
</script>
