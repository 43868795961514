import Vue from "vue";
import VueRouter from "vue-router";
import BootstrapVue from "bootstrap-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faTwitch,
  faSteam,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "./scss/_custom.scss";

import router from "./router.js";
import App from "./App.vue";
import NoData from "./components/NoData.vue";

import VueSupabase from "vue-supabase";

library.add(faBars);
library.add(faFacebook, faInstagram, faTwitter, faTwitch, faSteam);

Vue.use(VueRouter);
Vue.use(BootstrapVue);

Vue.use(VueSupabase, {
  supabaseUrl: process.env.VUE_APP_SUPABASE_URL,
  supabaseKey: process.env.VUE_APP_SUPABASE_ANON_KEY,
  supabaseOptions: {
    schema: "teamrme",
  }
});

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("no-data", NoData);

Vue.filter("datetime", function (value) {
  const date = new Date(value);
  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };
  return `${date.toLocaleDateString(
    undefined,
    dateOptions
  )} ${date.toLocaleTimeString(undefined, timeOptions)}`;
});

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
});
