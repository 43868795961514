<template>
  <div id="app">
    <div class="header">
      <SocialMedia/>
      <Navigation/>
    </div>
    <div class="content mb-5" id="content">
      <b-container class="pt-4">
        <div class="content__container">
          <router-view/>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
import Navigation from './components/Navigation.vue'
import SocialMedia from './components/SocialMedia.vue'

export default {
  name: 'App',
  components: { Navigation, SocialMedia }
}
</script>
