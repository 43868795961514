<template>
  <div :class="['social-media-icon', `social-media-icon--${media.type}`, { 'social-media-icon--color': color }]">
    <b-link :href="media.url" target="_blank" class="social-media-icon__link">
      <font-awesome-icon :icon="['fab', media.type]" size="1x"/>
    </b-link>
  </div>
</template>
<script>
export default {
  name: 'SocialMediaIcon',
  props: {
    media: {
      type: Object,
      required: true
    },
    color: {
      type: Boolean,
      default: false
    }
  }
}
</script>
