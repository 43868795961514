<template>
  <b-card class="match-card" :class="getMatchState" border-variant="light">
    <template #header>
      <div class="match-card__header">
        <div class="match-card__title">{{ match.title }}</div>
        <div v-if="match.state < 2" class="match-card__result">{{ match.result }}</div>
        <div class="match-card__subtitle">{{ match.subtitle }}</div>
        <div class="match-card__date">{{ match.date | datetime }}</div>
      </div>
    </template>
    <div class="match-card__text">{{ match.description }}</div>
  </b-card>
</template>
<script>
export default {
  name: 'MatchCard',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  computed: {
    getMatchState () {
      switch (this.match.state) {
        case '0':
          return 'match-card--lost'
        case '1':
          return 'match-card--won'
        case '2':
          return 'match-card--tie'
        case '3':
        default:
          return 'match-card--pending'
      }
    }
  }
}
</script>
