<template>
  <div class="main-nav" id="navigation">
    <b-container>
      <b-navbar toggleable="md">
        <b-navbar-brand>
          <router-link to="/">
            <b-img class="navbar-nav-logo" src="../img/RME.png"/>
          </router-link>
        </b-navbar-brand>

        <b-navbar-toggle target="main-nav-collapse">
          <font-awesome-icon icon="bars"/>
        </b-navbar-toggle>
        <b-collapse v-model="isCollapseOpen" id="main-nav-collapse" is-nav>
          <b-navbar-nav class="d-flex">
            <router-link to="/" class="navbar-nav-link">Feed</router-link>
            <router-link to="/team" class="navbar-nav-link">Team</router-link>
            <router-link to="/match-history" class="navbar-nav-link">Match history</router-link>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      isCollapseOpen: false
    }
  },
  mounted () {
    const content = document.querySelector('#content')
    const navigation = document.querySelector('#navigation')
    const navigationOffset = navigation.offsetTop
    window.onscroll = () => {
      if (this.isCollapseOpen) {
        this.$root.$emit('bv::toggle::collapse', 'main-nav-collapse')
      }

      if (window.pageYOffset > navigationOffset) {
        navigation.classList.add("main-nav--sticky")
        content.classList.add("content--scrolled")
      } else {
        navigation.classList.remove("main-nav--sticky")
        content.classList.remove("content--scrolled")
      }
    }
  }
}
</script>
