import VueRouter from 'vue-router';
import Home from './components/Home.vue'
import Team from './components/Team.vue'
import MatchHistory from './components/MatchHistory.vue'
import Merch from './components/Merch.vue'

let routes=[
  { path: '/', component: Home },
  { path: '/team', component: Team },
  { path: '/match-history', component: MatchHistory },
  { path: '/merch', component: Merch }
];

export default new VueRouter({
  routes,
  mode: 'history'
});