<template>
  <div v-if="loaded" class="match-history-wrapper">
    <template v-if="matchList.length">
      <div v-for="match in matchList" :key="match.id">
        <match-card :match="match"/>
      </div>
    </template>
    <no-data v-else></no-data>
  </div>
</template>

<script>
import MatchCard from './MatchCard'

export default {
  name: 'MatchHistory',
  components: { MatchCard },
  data () {
    return {
      loaded: false,
      matchList: []
    }
  },
  async created () {
    await this.fetchData()
    this.loaded = true
  },
  methods: {
    async fetchData () {
      const { data, error } = await this.$supabase.from("Matches")
        .select("*")
        .order("date", { ascending: false });

      if (error) {
        this.$bvToast.toast(error.message, {
          title: 'Unable to fetch matches!',
          variant: 'danger'
        })
        this.matchList = []
      } else {
        this.matchList = data
      }
    }
  }
}
</script>
