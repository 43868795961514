<template>
  <div class="social-media">
    <b-container class="social-media__container">
      <social-media-icon v-for="(media, index) in mediaList" :key="index" :media="media"/>
    </b-container>
  </div>
</template>
<script>
import SocialMediaIcon from './SocialMediaIcon.vue'

export default {
  name: 'SocialMedia',
  components: { SocialMediaIcon },
  data () {
    return {
      mediaList: [
        { type: 'twitter', url: 'https://twitter.com/TeamRME'},
        { type: 'instagram', url: 'https://www.instagram.com/teamrmeofficial'},
        { type: 'twitch', url: 'https://www.twitch.tv/teamrme'},
        { type: 'facebook', url: 'https://www.facebook.com/Team-Rush-Mid-Everyday-116595616850525'},
        { type: 'steam', url: 'https://steamcommunity.com/groups/rushmideveryday'}
      ]
    }
  }
}
</script>
