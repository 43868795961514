<template>
  <div v-if="loaded" class="team">
    <template v-if="memberList.length">
      <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="4">
        <team-member v-for="(member, index) in memberList"
                    :key="index"
                    :member="member"
        />
      </b-row>
    </template>
    <no-data v-else></no-data>
  </div>
</template>

<script>
import TeamMember from './TeamMember.vue'

export default {
  name: 'Team',
  components: { TeamMember },
  data () {
    return {
      loaded: false,
      memberList: [],
      rolesList: [],
      socialsList: []
    }
  },
  async created () {
    await this.fetchData()
    this.mergeTables()
    this.loaded = true
  },
  methods: {
    async fetchData () {
      const member = await this.$supabase.from("Member").select("*")

      if (member.error) {
        this.$bvToast.toast(member.error.message, {
          title: 'Unable to fetch member!',
          variant: 'danger'
        })
        this.memberList = []
      } else {
        this.memberList = member.data
      }

      const socials = await this.$supabase.from("Socials").select("*")

      if (socials.error) {
        this.$bvToast.toast(socials.error.message, {
          title: 'Unable to fetch social media data!',
          variant: 'danger'
        })
        this.socialsList = []
      } else {
        this.socialsList = socials.data
      }
    },
    mergeTables () {
      this.memberList.forEach(member => {
        member.socialMedia = []
      })

      if (this.socialsList.length) {
        this.socialsList.forEach(socials => {
          const member = this.memberList.find(member => {
            return member.id === socials.fk_member
          })

          member.socialMedia.push(socials)
        })
      }
    }
  }
}
</script>
