<template>
  <div class="no-data">
    <h2>Ooups!</h2>
    <div>Seems like no data is available</div>
  </div>
</template>

<script>
export default {
  name: 'NoData'
}
</script>
