<template>
  <b-col class="mb-3">
    <b-card :img-src="imageUrl" :img-alt="member.name" class="team-member">
      <b-card-title class="team-member__title">
        <b-img :src="roleIcon" :alt="member.role" class="team-member__role-icon"/>
        <div class="team-member__name ml-2">{{ member.nickname || member.ign }}</div>
      </b-card-title>
      <b-card-text>
        <hr v-if="member.socialMedia.length">
        <div class="d-flex justify-content-around">
          <social-media-icon v-for="(media, index) in member.socialMedia" :key="index" :media="media" color/>
        </div>
      </b-card-text>
    </b-card>
  </b-col>
</template>

<script>
import SocialMediaIcon from './SocialMediaIcon.vue'

export default {
  name: 'TeamMember',
  components: { SocialMediaIcon },
  props: {
    member: {
      type: Object,
      required: true
    }
  },
  computed: {
    fileName () {
      return this.member.ign.toLowerCase().replace(/\s/g, '')
    },
    imageUrl () {
      try {
        return require(`../img/player/${this.fileName}.png`)
      } catch (err) {
        return require(`../img/player/placeholder.png`)
      }
    },
    roleIcon () {
      return require(`../img/roles/${this.member.role}.png`)
    }
  }
}
</script>
